import React, { useEffect, useState } from "react"
import DetailsIcon from "../fundamentals/details-icon"
import ClipboardCopyIcon from "../fundamentals/icons/clipboard-copy-icon"
import ClockIcon from "../fundamentals/icons/clock-icon"
import CoinsIcon from "../fundamentals/icons/coins-icon"
import CustomerIcon from "../fundamentals/icons/customer-icon"
import EyeIcon from "../fundamentals/icons/eye-icon"
import GearIcon from "../fundamentals/icons/gear-icon"

import TagIcon from "../fundamentals/icons/tag-icon"
import TaxesIcon from "../fundamentals/icons/taxes-icon"
import { navigate, } from "gatsby"

import SidebarMenuItem from "./SideBarItem"
import { useNavigate, useParams } from "@reach/router"
import { useLocation } from "@reach/router"

const ICON_SIZE = 18

interface IProps {
  children?: React.ReactNode
}

const Sidebar: React.FC<IProps> = (props: IProps) => {
  const [currentlyOpen, setCurrentlyOpen] = useState(-1)
  const [type, setType] = useState<string>('')
  const location = useLocation();
  const params = useParams()

  // const { store } = useAdminStore()

  const triggerHandler = () => {
    const id = triggerHandler.id++
    return {
      open: currentlyOpen === id,
      handleTriggerClick: () => setCurrentlyOpen(id),
    }
  }

  useEffect(() => {
    setType(location.pathname.split('/')[1])
  }, [])
  // We store the `id` counter on the function object, as a state creates
  // infinite updates, and we do not want the variable to be free floating.
  triggerHandler.id = 0
  return (
    <>
      <div className="min-w-sidebar max-w-sidebar h-screen overflow-y-auto bg-gray-0 border-r border-grey-20  p-2 ">
        {/* <div className="flex items-center  px-2.5 pb-6 w-full mb-4 text-black">
          <span className="font-semibold ml-2.5 text-xl ">{"Salon"}</span>
        </div> */}
        <div className="px-base py-24 pb-4 rounded-lg h-fit ">
          <SidebarMenuItem
            pageLink={`/${type}/dashboard`}
            icon={<TagIcon size={ICON_SIZE} />}
            text={"Dashboard"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={`/${type}/appointments`}
            icon={<ClockIcon size={ICON_SIZE} />}
            text={"Appointments"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={`/${type}/clients`}
            icon={<CustomerIcon size={ICON_SIZE} />}
            text={"Clients"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={`/${type}/services`}
            icon={<ClipboardCopyIcon size={ICON_SIZE} />}
            text={"Services"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={`/${type}/messages`}
            icon={<DetailsIcon size={ICON_SIZE} />}
            text={"Messages"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={`/${type}/reviews`}
            icon={<EyeIcon size={ICON_SIZE} />}
            text={"Reviews"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            disabled={true}
            pageLink={`/${type}/finance`}
            icon={<CoinsIcon size={ICON_SIZE} />}
            text={"Finances"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            disabled={true}
            pageLink={`/${type}/setting`}
            icon={<GearIcon size={ICON_SIZE} />}
            text={"Setting"}
            triggerHandler={triggerHandler}
          />
        </div>
      </div>
    </>
  )
}

export default Sidebar
