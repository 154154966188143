import React, { useContext } from "react"
import { Toaster } from "react-hot-toast"
import Sidebar from "./Sidebar"
import TopBar from "./TopBar"
import { AccountContext } from "../../context/account"
import { ArrowPathIcon } from "@heroicons/react/24/outline"

interface IProps {
  children?: React.ReactNode
}

const Layout: React.FC<IProps> = (props: IProps) => {
  const {
    render
  } = useContext(AccountContext)
  return (
    <>{
      !render ? <div className="h-screen">
        <div className="h-full grid">
          <div className="place-self-center">
            <ArrowPathIcon className="w-20 h-20 animate-spin" />
          </div>
        </div>
      </div> : <div className="flex w-full h-screen inter-base-regular text-grey-90">
        <Toaster
          containerStyle={{
            top: 74,
            left: 24,
            bottom: 24,
            right: 24,
          }}
        />
        <Sidebar />
        <div className="flex flex-col flex-1">
          <div className=" bg-grey-5">
            <TopBar />
          </div>
          {/* hello */}
          <div className="p-10 bg-grey-5 min-h-content overflow-y-auto">
            {/* <main className="xsmall:mx-base small:mx-xlarge medium:mx-4xlarge large:mx-auto large:max-w-7xl large:w-full h-full"> */}
            <main>{props.children}</main>
          </div>
        </div>
      </div>
    }

    </>
  )
}

export default Layout
